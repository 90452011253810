<script setup lang="ts">
import {useMyRoutePaths} from "~/composables/route/useMyRoutePaths";
import type {ApiPostSeriesProps} from "~/projectCommon/frontend/model/ApiPostSeriesProps";

const {t} = useI18n()

const props = defineProps<{
  postSeriesList: ApiPostSeriesProps[]
}>()

const routePaths = useMyRoutePaths()
</script>

<template>
  <div class="container">
    <ul class="row">
      <li v-for="postSeries in props.postSeriesList" :key="postSeries.id" class="col-12 col-md-6 col-lg-3 mb-4 post-list-item">
        <div class="card">
          <a v-if="postSeries.ogpImageFile" :href="routePaths.postSeries(postSeries.id)">
            <img :src="postSeries.ogpImageFile?.displayImageFile.url"
                 class="card-img-top ogp-image"
                 alt="thumbnail" >
          </a>
          <div class="card-body">
            <div class="text-div">
              <h5 class="card-title">
                <a :href="routePaths.postSeries(postSeries.id)">{{ postSeries.title }}</a>
              </h5>
              <p class="card-text">{{ postSeries.excerpt }}</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
ul.row {
  padding: 0;
  list-style: none;
}
</style>